import simpleCrypto, { safeDecrypt } from 'lib/utils/crypto-encode';
import { differenceInSeconds, formatISO, parseISO } from 'date-fns';

export type VisaTimeStampType = {
  lat: number;
  lng: number;
  timestamp: string;
};

export const VISA_KEY = '__vsa_storage';

export const isVisaExpired = (
  visa: VisaTimeStampType,
  expirySeconds = 600,
): boolean =>
  Math.abs(differenceInSeconds(parseISO(visa?.timestamp), Date.now())) >
  expirySeconds;

export const setVisaTimeStamp = (
  lat: number,
  lng: number,
  timestamp = formatISO(Date.now()),
) => {
  const encrypted = simpleCrypto.encrypt({
    lat,
    lng,
    timestamp,
  });
  const stringified = JSON.stringify(encrypted);

  window.localStorage.setItem(VISA_KEY, stringified);
};

export const getVisaTimeStamp = (): VisaTimeStampType | null => {
  const rawVisa = window.localStorage.getItem(VISA_KEY);
  if (!rawVisa) {
    return null;
  }
  const parsed = JSON.parse(rawVisa);
  const decrypted = safeDecrypt<VisaTimeStampType>(parsed);

  if (!decrypted || isVisaExpired(decrypted)) {
    return null;
  }

  return decrypted;
};
