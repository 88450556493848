import { useEffect, useRef } from 'react';
import { IS_PROD_ENV } from 'lib/constants/global';

export default function DataDogRum(): null {
  const hasInitialized = useRef(false);
  useEffect(() => {
    (async (): Promise<void> => {
      if (hasInitialized.current) return;

      if (IS_PROD_ENV && process.env.NEXT_PUBLIC_DATADOG_TOKEN) {
        const { datadogRum } = await import('@datadog/browser-rum');
        datadogRum.init({
          applicationId: '92ec8f12-06b5-4dcb-b22b-1b58bd169720',
          clientToken: `${process.env.NEXT_PUBLIC_DATADOG_TOKEN}`,
          env: process.env.NEXT_PUBLIC_APP_ENV,
          service: 'jackpocket-web-app',
          silentMultipleInit: true,
          site: 'datadoghq.com',
          sampleRate: 40,
          replaySampleRate: 10,
          trackInteractions: true,
          version: process.env.NEXT_PUBLIC_APP_VERSION,
        });
        datadogRum.startSessionReplayRecording();
        hasInitialized.current = true;
      }
    })();
  }, []);
  return null;
}
