import SimpleCrypto from 'simple-crypto-js';

const simpleCrypto = new SimpleCrypto(
  process.env.NEXT_PUBLIC_CRYPTO_SECRET_KEY,
);

/**
 * `SimpleCryto.decrypt` function wrapped in a try catch block that returns `null` if there is an error.
 * This was originally designed to be used when the base hashing algorithm is updated and decrypting any existing data that was encrypted with the old algorithm fails.
 */
export const safeDecrypt = <T>(cipher: string) => {
  try {
    return simpleCrypto.decrypt(cipher) as T;
  } catch (e) {
    return null;
  }
};

export default simpleCrypto;
