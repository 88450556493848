import React, { useState, useEffect } from 'react';
import { AppProps } from 'next/app';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Provider as AtomicStateProvider } from 'jotai';
import GlobalStyle from 'lib/styles/global';
import NProgressBar from 'components/atoms/nprogress-bar';
import { TrackingClient, TrackingClientProvider } from 'lib/tracking';
import NiceModal from '@ebay/nice-modal-react';
import ErrorPage from 'pages/_error';
import DataDogRum from 'components/atoms/datadog-rum';
import theme from 'lib/styles/theme';
import BugSnag from 'lib/bugsnag';
import { ThemeProvider } from 'styled-components';
import { IS_PROD_ENV, IS_TEST_ENV } from 'lib/constants/global';
import { LDProvider } from 'lib/launch-darkly/client';
import { DehydratedLaunchDarklyState } from 'lib/launch-darkly/server';
import BugSnagSetter from 'components/atoms/bugsnag-setter';

const GeoPrompt = dynamic(() => import('components/organisms/geo-prompt'), {
  ssr: false,
});

const LocationSelector = dynamic(
  () => import('components/molecules/location-selector'),
  { ssr: false },
);

const ErrorBoundary: any = BugSnag.getPlugin('react');

type Props = AppProps<{
  dehydratedState: any;
  dehydratedLaunchDarklyState?: DehydratedLaunchDarklyState;
  disableAppsflyerBanner?: boolean;
}>;

const App = ({ Component, ...rest }: Props) => {
  const { disableAppsflyerBanner, dehydratedLaunchDarklyState } =
    rest.pageProps;

  const [queryClient] = useState(
    () =>
      new QueryClient({
        queryCache: new QueryCache({
          onError: (error: any) => {
            const { status } = error;

            if (status === 401) {
              window.location.href = '/logout?status=unauthorized';
            }
          },
        }),
      }),
  );

  const [trackingClient] = useState(new TrackingClient());

  useEffect(() => {
    if (IS_PROD_ENV) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      console.debug = function () {};
    }
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <NProgressBar />
        <ErrorBoundary FallbackComponent={ErrorPage}>
          <QueryClientProvider client={queryClient}>
            <LDProvider
              context={dehydratedLaunchDarklyState?.context}
              flags={dehydratedLaunchDarklyState?.featureFlags}
            >
              <TrackingClientProvider
                client={trackingClient}
                disableAppsflyerBanner={disableAppsflyerBanner}
              >
                <DataDogRum />
                <BugSnagSetter />
                <AtomicStateProvider>
                  <NiceModal.Provider>
                    <Head>
                      <meta
                        name="viewport"
                        content="initial-scale=1.0, width=device-width, user-scalable=0, viewport-fit=cover"
                      />
                    </Head>
                    <Component {...rest} />
                    <LocationSelector />
                    <GeoPrompt />
                  </NiceModal.Provider>
                </AtomicStateProvider>
              </TrackingClientProvider>
            </LDProvider>
            {!IS_TEST_ENV && <ReactQueryDevtools />}
          </QueryClientProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </>
  );
};

export default App;
