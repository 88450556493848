import React, { ReactNode } from 'react';
import dynamic from 'next/dynamic';
import { useIpLocation } from 'components/hooks/use-locations';
import { useGameLocation } from 'lib/atomic-state';
import { Main, Content } from './styles';

const DynamicFooter = dynamic(() => import('components/molecules/footer'), {
  suspense: false,
  ssr: false,
});

type Props = {
  children: ReactNode;
  splitBg?: boolean;
};

export const WithFooter = ({ children, splitBg }: Props) => {
  const { ipLocation } = useIpLocation();
  const { gameLocation } = useGameLocation();

  const locationKey = gameLocation?.value || ipLocation?.IPstate;

  return (
    <Main>
      <Content splitBg={splitBg}>{children}</Content>
      {}
      <DynamicFooter locationKey={locationKey} />
    </Main>
  );
};

export default WithFooter;
