import { atom, useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { nanoid } from 'nanoid';
import { useMemo } from 'react';
import { UsStateType } from 'lib/common/states';
import { PlayRequest } from 'lib/types/play';
import { DeviceVerificationData } from 'lib/types/user';
import simpleCrypto, { safeDecrypt } from 'lib/utils/crypto-encode';
import { VISA_KEY } from 'lib/visa-timestamp';

export const playOptionsState = atomWithStorage<PlayRequest | null>(
  'currentPlayOptions',
  null,
);

export const nyExcludedAtom = atomWithStorage<boolean | null>(
  'nyExcluded',
  null,
);

export const isEmbeddedAtom = atomWithStorage<boolean>('embedded', false);

export const gameIdAtom = atomWithStorage<number | null>('currentGameId', null);

export const locationSelectorVisible = atom<boolean>(false);

export const scratcherOnboardSeen = atomWithStorage<boolean>('__jp_sob', false);

export const geoSessionAtom = atom<any>({
  dProfileSessionId: nanoid(12),
  html5SessionId: nanoid(12),
});

export const geoGateAtom = atom<boolean>(false);

export const ticketSecurityConfirmationAtom = atomWithStorage<boolean>(
  'ticketSecurityConfirmation',
  false,
);

export const plaidLinkTokenAtom = atomWithStorage<string | null>(
  'plaidLinkTokenValue',
  null,
);

export const gameLocationAtom = atomWithStorage<string | null>(
  '__lko_id',
  null,
);

export const nyExclusionAtom = atomWithStorage<boolean>('__jp_nyexc', false);

const deviceVerificationAtom = atomWithStorage<string | null>('__jp_dvd', null);

export const useGameLocation = () => {
  const [selectedState, setSelectedState] = useAtom(gameLocationAtom);

  const setGameLocation = (location: UsStateType) => {
    setSelectedState(simpleCrypto.encrypt(location));
    window.localStorage.removeItem(VISA_KEY);
  };
  const gameLocation: UsStateType | null = useMemo(
    () => (selectedState ? safeDecrypt<UsStateType>(selectedState) : null),
    [selectedState],
  );

  return { gameLocation, setGameLocation };
};

export const useDeviceVerificationData = () => {
  const [encryptedData, setEncryptedData] = useAtom(deviceVerificationAtom);

  const setDeviceVerificationData = (data: DeviceVerificationData | null) =>
    data
      ? setEncryptedData(simpleCrypto.encrypt(data))
      : setEncryptedData(null);

  const deviceVerificationData = encryptedData
    ? safeDecrypt<DeviceVerificationData>(encryptedData)
    : null;

  return { deviceVerificationData, setDeviceVerificationData };
};
