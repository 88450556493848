import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import useSession from 'components/hooks/use-session';
import {
  jackpocketAPI,
  nextServerAPI,
  setRequestHeader,
  getJackpocketAPI,
} from 'lib/api';
import { User, UserResponse } from 'lib/types/user';
import { useDeviceVerificationData } from 'lib/atomic-state';
import { IS_PROD_ENV } from 'lib/constants/global';

export type SignupData = {
  email: string;
  phone: string;
  phone_access_token?: number;
  phone_number?: string;
};

export type SignInData = {
  phone_number: string;
};

type PhoneVerifyPayload = {
  phone_access_token: string;
  phone_number?: string;
};

export async function fetchUser(id: number, token: string) {
  const { data } = await jackpocketAPI.get(`users/${id}`, {
    params: {
      id,
      authentication_token: token,
    },
  });

  return data.user;
}

export async function fetchUserSSR(id: number, token: string) {
  const jpAPI = getJackpocketAPI();
  const { data } = await jpAPI.get<UserResponse>(`users/${id}`, {
    params: {
      id,
      authentication_token: token,
    },
  });
  return data;
}

export function useUser(initialData?: User) {
  const { sessionData } = useSession();

  const res = useQuery({
    queryKey: ['user'],
    queryFn: async () =>
      fetchUser(sessionData.user?.id, sessionData.user?.authentication_token),
    enabled: Boolean(sessionData?.user?.id),
    initialData,
  });

  const user = res?.data;

  /* eslint-disable */
  const referralLink = user
    ? `https://jackpocket${IS_PROD_ENV ? '' : '-staging'}.com/referrals/${user.referral_token}`
    : null;
  /* eslint-enable */

  return { ...res, user, referralLink };
}

export async function createUser({
  user,
  locationCode,
}: {
  user: SignupData;
  locationCode: string;
}) {
  setRequestHeader(jackpocketAPI, 'JP-Display-Location-Code', locationCode);

  const { data } = await jackpocketAPI.post<UserResponse>('users', { user });
  return data.user;
}

export async function requestOTP(phoneNumber?: string) {
  const { data } = await jackpocketAPI.post<{ token_length: number }>(
    'phone/token',
    { phone_number: phoneNumber },
  );

  return data.token_length;
}

async function verifyPhone(phoneVerification: PhoneVerifyPayload) {
  const { data } = await jackpocketAPI.post<UserResponse>(
    'phone/authenticate',
    phoneVerification,
  );

  return data.user;
}

export function useVerifyPhone() {
  const queryClient = useQueryClient();
  const { setDeviceVerificationData } = useDeviceVerificationData();

  return useMutation({
    mutationFn: verifyPhone,
    onSuccess: async (user: User) => {
      queryClient.setQueryData(['user'], user);
      await nextServerAPI.post('login', user);
    },
    onError: (error: any, phoneVerification) => {
      const { code, meta } = error[0] as {
        code: string;
        meta: any;
      };

      if (code === 'device_not_confirmed') {
        setDeviceVerificationData({
          obfuscated_email: meta.obfuscated_email,
          phone: phoneVerification.phone_number!,
          uuid: meta.uuid,
        });
      }
    },
  });
}
