/* eslint-disable lines-between-class-members */
/* eslint-disable class-methods-use-this */
import BugSnag from 'lib/bugsnag';
import { FundingHistory } from 'lib/types/fundRequest';
import { Order } from 'lib/types/order';
import { Play } from 'lib/types/play';
import { User } from 'lib/types/user';
import { Tracker } from './Tracker';

export class BrazeTracker implements Tracker {
  async initialize() {
    try {
      if (!process.env.NEXT_PUBLIC_BRAZE_KEY) {
        return;
      }

      const braze = await import('lib/braze');

      braze.initialize(process.env.NEXT_PUBLIC_BRAZE_KEY, {
        allowUserSuppliedJavascript: true,
        baseUrl: process.env.NEXT_PUBLIC_BRAZE_BASE_URL as string,
        doNotLoadFontAwesome: true,
        enableLogging: false,
      });

      await this.configureSoftPushPrompt();

      braze.openSession();
    } catch (e: any) {
      BugSnag.notify(e);
    }
  }

  async configureSoftPushPrompt() {
    const braze = await import('lib/braze');

    braze.subscribeToInAppMessage((inAppMessage) => {
      if (
        inAppMessage instanceof braze.ModalMessage ||
        inAppMessage instanceof braze.FullScreenMessage
      ) {
        const keyValuePairs = inAppMessage.extras || {};

        if (keyValuePairs['msg-id'] === 'push-primer') {
          if (
            braze.isPushSupported() === false ||
            braze.isPushPermissionGranted() ||
            braze.isPushBlocked()
          ) {
            return;
          }

          if (inAppMessage.buttons[0]) {
            inAppMessage.buttons[0].subscribeToClickedEvent(() => {
              braze.requestPushPermission();
            });
          }
        }
      }

      braze.showInAppMessage(inAppMessage);
    });
  }

  async updateUserProfile(user: User) {
    try {
      const braze = await import('lib/braze');
      const brazeUser = braze.getUser();
      brazeUser?.setFirstName(user.first_name || null);
      brazeUser?.setLastName(user.last_name || null);
      brazeUser?.setEmail(user.email);
      brazeUser?.setPhoneNumber(user.phone || null);
      const [year, month, day] = user.birthdate
        ? user.birthdate.split('-').map((parts) => parseInt(parts, 10))
        : [null, null, null];
      brazeUser?.setDateOfBirth(year, month, day);

      const { metrics, referral_token } = user;

      brazeUser?.setCustomUserAttribute('referral_token', referral_token);
      brazeUser?.setCustomUserAttribute('created_at', metrics.created_at);
      brazeUser?.setCustomUserAttribute(
        'registration_location_code',
        metrics.registration_location_code || null,
      );
      brazeUser?.setCustomUserAttribute(
        'registered_platform',
        metrics.registered_platform,
      );
    } catch (e: any) {
      BugSnag.notify(e);
    }
  }

  async identify(user: User) {
    try {
      const braze = await import('lib/braze');
      braze.changeUser(`${user.id}`);
      await this.updateUserProfile(user);
    } catch (e: any) {
      BugSnag.notify(e);
    }
  }

  async trackPurchaseEvent({
    productId,
    price,
    properties,
    quantity = 1,
  }: {
    productId: string;
    price: number;
    properties?: Record<string, unknown>;
    quantity?: number;
  }) {
    try {
      const braze = await import('lib/braze');
      braze.logPurchase(productId, price, 'USD', quantity, properties);
    } catch (e: any) {
      BugSnag.notify(e);
    }
  }

  async trackPlayPurchase(order: Play) {
    const { total, lottery_id } = order;

    await this.trackPurchaseEvent({
      productId: 'played_game',
      price: total / 100,
      properties: { lottery_id },
    });
  }

  async trackDigitalScratcherPurchase(order: Order, data: any) {
    const { total } = order;

    await this.trackPurchaseEvent({
      productId: 'digital_scratcher_order',
      price: total / 100,
      properties: {
        jp_product_type: 'digital_scratcher',
        ...data,
      },
    });
  }

  async trackFundRequest(fundRequest: FundingHistory) {
    await this.trackPurchaseEvent({
      productId: 'funded_account',
      price: 0,
      properties: {
        amount_cents: fundRequest.amount,
        service_fee_cents: fundRequest.service_fee,
      },
    });
  }

  async trackEvent(eventName: string, data: any = {}) {
    try {
      const braze = await import('lib/braze');
      braze.logCustomEvent(eventName, data);
    } catch (e: any) {
      BugSnag.notify(e);
    }
  }

  async trackRegistrationSuccess() {
    await this.trackEvent('Registration Completed');
  }
}
