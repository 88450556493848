import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';
import theme from './theme';

export const GlobalStyle = createGlobalStyle`
 ${normalize}

 @font-face {
   font-family: 'GrotaSans-ExtraBold';
   font-style: normal;
   font-weight: 800;
   src: url('/fonts/fonts-grotasans-extrabold.woff') format('woff');
   font-display: swap;
 }

 @font-face {
   font-family: 'Proxima Nova';
   font-style: normal;
   font-weight: 400;
   src: url('/fonts/fonts-proximanova-regular.woff') format('woff');
   font-display: swap;
 }

 @font-face {
   font-family: 'Proxima Nova';
   font-style: normal;
   font-weight: 600;
   src: url('/fonts/fonts-proximanova-semibold.woff') format('woff');
   font-display: swap;
 }

 @font-face {
   font-family: 'Proxima Nova';
   font-style: normal;
   font-weight: 700;
   src: url('/fonts/fonts-proximanova-bold.woff') format('woff');
   font-display: swap;
 }

 * {
   box-sizing: border-box;
 }

 html, body {
   overscroll-behavior: none;
 }



 body{
   font-family: 'Proxima Nova',sans-serif;
   font-weight: 400;
   font-size: 16px;
   background-color: ${theme.colors.bg};
   color: ${theme.colors.text};
 }

 h1,h2,h3,h4,h5,h6{
   font-weight: 700;
 }

 p{
   font-size: 18px;
 }

 a{
   text-decoration: none;
   transition: color 0.2s ease-in-out;
   &:hover{
     cursor: pointer;
   }
 }

 button {
   color: ${theme.colors.text};
   background-color: transparent;
   outline: none;
   border: none;
   transition: background-color 0.2s ease-in-out;
   &:hover{
     cursor: pointer;
   }
 }
`;

export default GlobalStyle;
