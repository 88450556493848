/* eslint-disable no-shadow */
/* eslint-disable class-methods-use-this */
/* eslint-disable lines-between-class-members */
import allSettled from 'promise.allsettled';
import { IS_TEST_ENV } from 'lib/constants/global';
import { FundingHistory } from 'lib/types/fundRequest';
import { LotteryDetail, PlayOptionType } from 'lib/types/lottery';
import { User } from 'lib/types/user';
import { MultiplayOrderRequest, Order } from 'lib/types/order';
import { PickType, Play } from 'lib/types/play';
import {
  AmplitudeTracker,
  AppsflyerTracker,
  BrazeTracker,
  FirebaseTracker,
  GoogleTagManagerTracker,
  MockTracker,
  Tracker,
} from './trackers';

export enum Vendor {
  Amplitude,
  Appsflyer,
  Braze,
  Firebase,
  GoogleTagManager,
}

export class TrackingClient {
  private isInitialized = false;
  private postInitQueue: (() => void)[] = [];
  private trackerMap: Map<Vendor, Tracker>;
  private trackers: Tracker[];

  constructor() {
    if (!IS_TEST_ENV) {
      this.trackerMap = new Map<Vendor, Tracker>([
        [Vendor.Amplitude, new AmplitudeTracker()],
        [Vendor.Appsflyer, new AppsflyerTracker()],
        [Vendor.Braze, new BrazeTracker()],
        [Vendor.Firebase, new FirebaseTracker()],
        [Vendor.GoogleTagManager, new GoogleTagManagerTracker()],
      ]);
    } else {
      this.trackerMap = new Map<Vendor, Tracker>([
        [Vendor.Amplitude, new MockTracker()],
        [Vendor.Appsflyer, new MockTracker()],
        [Vendor.Braze, new MockTracker()],
        [Vendor.Firebase, new MockTracker()],
        [Vendor.GoogleTagManager, new MockTracker()],
      ]);
    }

    this.trackers = Array.from(this.trackerMap.values());
  }

  get appsflyer() {
    return this.trackerMap.get(Vendor.Appsflyer) as AppsflyerTracker;
  }

  get amplitude() {
    return this.trackerMap.get(Vendor.Amplitude) as AmplitudeTracker;
  }

  get braze() {
    return this.trackerMap.get(Vendor.Braze) as BrazeTracker;
  }

  get firebase() {
    return this.trackerMap.get(Vendor.Firebase) as FirebaseTracker;
  }

  get googleTagManager() {
    return this.trackerMap.get(
      Vendor.GoogleTagManager,
    ) as GoogleTagManagerTracker;
  }

  getCheckoutType(pickType: PickType) {
    switch (pickType) {
      case PickType.BulkOrder:
        return 'bulkorder_quickpicks';
      case PickType.QuickPick:
        return 'quickpick';
      case PickType.SuperPick:
        return 'super_pick';
      case PickType.PickYourOwn:
        return 'chooser';
      default:
        return '';
    }
  }

  async initialize() {
    const clientInitializations = this.trackers.map((tracker) =>
      tracker.initialize?.(),
    );

    // Promise.allSettled shim to support older browsers
    allSettled(clientInitializations);
    allSettled.shim();

    await Promise.allSettled(clientInitializations);
    this.isInitialized = true;

    this.postInitQueue.forEach((queuedAction) => queuedAction());
    this.postInitQueue = [];
  }

  identify(user: User) {
    if (!this.isInitialized) {
      this.postInitQueue.push(() => this.identify(user));
      return;
    }

    this.trackers.forEach((tracker) => tracker.identify?.(user));
  }

  invalidateIdentity() {
    this.trackers.forEach((tracker) => tracker.invalidateIdentity?.());
  }

  setUserProperties(properties: { [key: string]: boolean | number | string }) {
    this.trackers.forEach((tracker) => tracker.setUserProperties?.(properties));
  }

  trackCheckoutInitiated(pickType: PickType) {
    if (!this.isInitialized) {
      this.postInitQueue.push(() => this.trackCheckoutInitiated(pickType));
      return;
    }

    this.trackEventForPrimaryTrackers('Checkout Initiated', {
      checkout_type: this.getCheckoutType(pickType),
    });
  }

  trackRegistrationSuccess(user: User, method = 'web') {
    if (!this.isInitialized) {
      this.postInitQueue.push(() =>
        this.trackRegistrationSuccess(user, method),
      );
      return;
    }

    this.identify(user);

    this.trackers.forEach((tracker) =>
      tracker.trackRegistrationSuccess(method),
    );
  }

  trackPaymentMethodAdded(paymentMethod: string, kind: string) {
    if (!this.isInitialized) {
      this.postInitQueue.push(() =>
        this.trackPaymentMethodAdded(paymentMethod, kind),
      );
      return;
    }

    this.trackers.forEach((tracker) =>
      tracker.trackPaymentMethodAdded?.(paymentMethod, kind),
    );
  }

  trackPlayPurchase(order: Play, user: User, pickType: PickType) {
    if (!this.isInitialized) {
      this.postInitQueue.push(() =>
        this.trackPlayPurchase(order, user, pickType),
      );
      return;
    }

    const playOptions = this.parsePlayOptions(order.options);
    const checkoutType = this.getCheckoutType(pickType);

    this.trackers.forEach((tracker) => {
      tracker.trackPlayPurchase(order, {
        isFirstPlay: user.plays_count === 0,
        checkoutType,
        playOptions,
      });
    });
  }

  trackMultiplayPurchase(
    order: Order,
    orderRequest: MultiplayOrderRequest,
    user: User,
  ) {
    if (!this.isInitialized) {
      this.postInitQueue.push(() =>
        this.trackMultiplayPurchase(order, orderRequest, user),
      );
      return;
    }

    const playOptions = this.parsePlayOptions(orderRequest.data.play.options);

    this.trackers.forEach((tracker) => {
      tracker.trackMultiplayPurchase?.(order, {
        isFirstPlay: user.plays_count === 0,
        checkoutType: this.getCheckoutType(PickType.BulkOrder),
        lotteryId: orderRequest.data.play.lottery_id,
        playOptions,
      });
    });
  }

  trackDigitalScratcherPurchase(order: Order, data: any) {
    if (!this.isInitialized) {
      this.postInitQueue.push(() =>
        this.trackDigitalScratcherPurchase(order, data),
      );
      return;
    }

    this.trackers.forEach((tracker) => {
      tracker.trackDigitalScratcherPurchase?.(order, data);
    });
  }

  trackFundRequest(fundRequest: FundingHistory, user: User) {
    if (!this.isInitialized) {
      this.postInitQueue.push(() => this.trackFundRequest(fundRequest, user));
      return;
    }

    this.trackers.forEach((tracker) =>
      tracker.trackFundRequest(fundRequest, user),
    );
  }

  trackAddToCart(
    lottery: LotteryDetail,
    entriesCount: number,
    pickType: PickType,
    isCustomAmount?: boolean,
  ) {
    if (!this.isInitialized) {
      this.postInitQueue.push(() =>
        this.trackAddToCart(lottery, entriesCount, pickType),
      );
      return;
    }

    const checkoutType = this.getCheckoutType(pickType);

    this.trackers.forEach((tracker) =>
      tracker.trackAddToCart?.(
        lottery,
        entriesCount,
        checkoutType,
        isCustomAmount,
      ),
    );
  }

  trackEvent(event: string, data?: any) {
    if (!this.isInitialized) {
      this.postInitQueue.push(() => this.trackEvent(event, data));
      return;
    }

    this.trackers.forEach((tracker) => tracker.trackEvent(event, data));
  }

  trackEventFor(vendors: Vendor[], event: string, data?: any) {
    if (!this.isInitialized) {
      this.postInitQueue.push(() => this.trackEventFor(vendors, event, data));
      return;
    }

    vendors.forEach((vendor) => {
      this.trackerMap.get(vendor)?.trackEvent(event, data);
    });
  }

  trackEventForPrimaryTrackers(event: string, data?: any) {
    if (!this.isInitialized) {
      this.postInitQueue.push(() =>
        this.trackEventForPrimaryTrackers(event, data),
      );
      return;
    }

    this.amplitude.trackEvent(event, data);
    this.braze.trackEvent(event, data);
    this.firebase.trackEvent(event, data);
  }

  /**
   * Parses play options to flatten its array value into a string. Ex. `{ multiplier: ['1'] }` turns into `{ multiplier: '1' }`
   */
  parsePlayOptions(
    playOptions?:
      | {
          [key in PlayOptionType]?: string[];
        }
      | null,
  ) {
    if (!playOptions) return undefined;

    const parsedOptions = Object.entries(playOptions).reduce(
      (options, [option, value]) => ({
        ...options,
        [option]: value[0],
      }),
      {},
    );

    return parsedOptions as { [key in PlayOptionType]: string };
  }
}
