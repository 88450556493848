/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */

import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
} from 'axios';
import { nanoid } from 'nanoid';
import { UAParser } from 'ua-parser-js';
import { IS_SERVER } from 'lib/constants/global';

enum ContentType {
  application_json = 'application/json; charset=utf-8',
  json_api = 'application/vnd.api+json',
}

interface JsonApiErrorResponse {
  data: {
    errors: {
      code: string;
      details: string;
      meta: any;
      source: { pointer: string };
      status: string;
      title: string;
    }[];
  };
  status: number;
}

interface ApplicationJsonErrorResponse {
  status: number;
  data: {
    error?: string;
    errors?: {
      base: string[];
    };
  };
}

function parseResponse(response: AxiosResponse<any>) {
  return response;
}

function parseJsonApiErrors(response: JsonApiErrorResponse) {
  return response.data.errors.map((e) => ({
    code: e.code,
    details: e.details,
    meta: e.meta,
    source: e.source?.pointer.split('/data/attributes/')[1] || null,
    status: response.status,
    title: e.title,
  }));
}

function parseApplicationJsonError(response: ApplicationJsonErrorResponse) {
  return {
    ...response,
    message: response.data.error || response.data.errors?.base.join(' '),
  };
}

export function parseError(error: any) {
  if (error.response?.headers) {
    switch (error.response.headers['content-type']) {
      case ContentType.json_api:
        return Promise.reject(parseJsonApiErrors(error.response));
      case ContentType.application_json:
        return Promise.reject(parseApplicationJsonError(error.response));
      default:
        return Promise.reject(error.response.data);
    }
  }

  return Promise.reject(error);
}

export function errorToMessage(error: any) {
  if (Array.isArray(error)) {
    const formattedError = error.map((e) => e.details).join('\n');
    return formattedError;
  }

  return error.message as string;
}

export function setRequestHeader(
  instance: AxiosInstance,
  key: string,
  value: string,
) {
  instance.interceptors.request.use((config) => {
    config.headers = config.headers || ({} as AxiosRequestHeaders);
    config.headers[key] = value;
    return config;
  });
}

function getPsuedoDeviceUUID() {
  const key = '__jp_dvcid';
  const uuid = localStorage.getItem(key);

  if (!uuid) {
    const newUuid = nanoid(36);
    localStorage.setItem(key, newUuid);

    return newUuid;
  }

  return uuid;
}

function jackpocketDeviceLogHeaders() {
  if (IS_SERVER()) {
    return {
      Platform: 'web-server',
    };
  }

  const { browser, device, os } = UAParser();

  return {
    'JP-App-Version': process.env.NEXT_PUBLIC_APP_VERSION,
    'JP-Browser-Model': browser.name,
    'JP-Browser-Version': browser.version,
    'JP-Device-Model': device.model || os.name,
    'JP-Device-Version': os.version,
    'JP-Device-UUID': getPsuedoDeviceUUID(),
    Platform: 'web',
  };
}

const jackpocketConfig = {
  baseURL: process.env.NEXT_PUBLIC_JACKPOCKET_BASE_URL,
  headers: {
    'api-version': process.env.NEXT_PUBLIC_JACKPOCKET_API_VERSION,
    Accept: ContentType.json_api,
    Authorization: `Basic ${process.env.NEXT_PUBLIC_JACKPOCKET_API_KEY}`,
    'Content-Type': ContentType.json_api,
    ...jackpocketDeviceLogHeaders(),
  },
} as AxiosRequestConfig;

const nextServerConfig = {
  baseURL: '/api',
  headers: {
    Accept: ContentType.application_json,
    'Content-Type': ContentType.application_json,
  },
};

const payrangeConfig = {
  baseURL: process.env.NEXT_PUBLIC_PAYRANGE_BASE_URL,
  headers: {
    'Content-Type': ContentType.application_json,
    json: 'true',
    'x-pr-api-level': '11',
    'x-pr-app-id': `${process.env.NEXT_PUBLIC_PAYRANGE_APP_ID}`,
    'x-pr-app-secret': `${process.env.PAYRANGE_API_SECRET}`,
  },
};

// For server side use
export const getAPI = (config?: AxiosRequestConfig) => axios.create(config);

export const getJackpocketAPI = () => {
  const api = getAPI(jackpocketConfig);
  api.interceptors.response.use(parseResponse, parseError);
  return api;
};

export const getNextServerAPI = () => getAPI(nextServerConfig);

export const getPayRangeAPI = () => getAPI(payrangeConfig);

// For client side use
export const baseAPI = getAPI();

export const jackpocketAPI = getJackpocketAPI();

export const nextServerAPI = getNextServerAPI();

export const payRangeAPI = getPayRangeAPI();
