import React from 'react';
import { ButtonLight } from 'components/atoms/buttons';
import Logo from 'components/atoms/icons/logo';
import styled from 'styled-components';
import theme from 'lib/styles/theme';
import WithFooter from 'components/layouts/with-footer';

const { colors } = theme;

export const PageWrap = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: ${colors.jackpocketBlue};
  > svg {
    position: absolute;
    top: 32px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 200px;
    margin-bottom: 100px;
  }
`;

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;

  h1,
  p,
  p > a {
    color: ${colors.white};
    text-align: center;
  }
  > a {
    background-color: ${colors.white};
    margin-top: 32px;
  }
  > p {
    margin: 0 auto;
    max-width: 300px;
  }
`;

export default function ErrorPage() {
  return (
    <WithFooter>
      <PageWrap>
        <Logo isLight />
        <PageContent>
          <h1>Something went wrong...</h1>
          <p>Sorry about that. Head back to the homepage and try again.</p>

          <ButtonLight w={260} h={50} as="a" href="/" rel="noreferrer">
            Go home
          </ButtonLight>
        </PageContent>
      </PageWrap>
    </WithFooter>
  );
}
