/* eslint-disable class-methods-use-this */
/* eslint-disable lines-between-class-members */
import TagManager from 'react-gtm-module';

import BugSnag from 'lib/bugsnag';
import { FundingHistory } from 'lib/types/fundRequest';
import { Play } from 'lib/types/play';
import { User } from 'lib/types/user';
import { PlayPurchaseEventOptions, Tracker } from './Tracker';

export class GoogleTagManagerTracker implements Tracker {
  async initialize() {
    try {
      if (!process.env.NEXT_PUBLIC_GTM_ID) {
        throw new Error(
          'Cannot inititalize GoogleTagManagerTracker because the NEXT_PUBLIC_GTM_ID is missing',
        );
      }

      TagManager.initialize({
        gtmId: process.env.NEXT_PUBLIC_GTM_ID,
        auth: process.env.NEXT_PUBLIC_GTM_AUTH,
        preview: process.env.NEXT_PUBLIC_GTM_PREVIEW,
      });
    } catch (e: any) {
      BugSnag.notify(e);
    }
  }

  identify(user: User) {
    try {
      TagManager.dataLayer({
        dataLayer: {
          user_id: user.id,
          user_uuid: user.uuid,
        },
      });
    } catch (e: any) {
      BugSnag.notify(e);
    }
  }

  trackEvent(eventName: string, data: any = {}): void {
    try {
      TagManager.dataLayer({
        dataLayer: {
          event: eventName,
          ...data,
        },
      });
    } catch (e: any) {
      BugSnag.notify(e);
    }
  }

  trackRegistrationSuccess() {
    this.trackEvent('Registration Completed', {
      registrationCompleted: {
        timestamp: Date.now(),
      },
    });
  }

  trackPaymentMethodAdded(): void {
    this.trackEvent('Payment Method Added');
  }

  trackPlayPurchase(order: Play, options: PlayPurchaseEventOptions): void {
    this.trackEvent('Ticket Purchase Completed', {
      ticketPurchaseCompleted: {
        value: order.total,
        lottery_id: order.lottery_id,
        transaction_id: order.id,
        timestamp: Date.now(),
        is_first_play: options.isFirstPlay,
      },
    });
  }

  trackFundRequest(fundRequest: FundingHistory, user: User): void {
    const data = {
      fundAccountCompleted: {
        value: fundRequest.amount,
        transaction_id: fundRequest.id,
        timestamp: Date.now(),
      },
    };

    this.trackEvent('Fund Account Completed', data);

    if (user.metrics.total_fund_requests === 0) {
      this.trackEvent('First Deposit Completed', data);
    }
  }

  trackAddToCart(): void {
    this.trackEvent('Cart Item Added');
  }
}
