import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
} from 'react';
import useSession from 'components/hooks/use-session';
import { useUser } from 'components/hooks/use-signup-query';
import BugSnag from 'lib/bugsnag';
import { TrackingClient } from './tracking-client';

export interface TrackingClientProviderProps {
  client: TrackingClient;
  children: React.ReactNode;
  disableAppsflyerBanner?: boolean;
}

const TrackingContext = createContext<TrackingClient>({} as TrackingClient);

export const useTrackingClient = () => {
  const trackingClient = useContext(TrackingContext);

  return trackingClient;
};

export const TrackingClientProvider = ({
  client,
  children,
  disableAppsflyerBanner = false,
}: TrackingClientProviderProps) => {
  const [initialized, setInitialized] = useState(false);
  const { user } = useUser();
  const { sessionData } = useSession();
  const appsflyerBannerRef = useRef<HTMLDivElement>(null);

  const hideAppsflyerBanner = () => {
    appsflyerBannerRef.current!.style.display = 'none';
  };

  // Initialize TrackingClient
  useEffect(() => {
    const initialize = async () => {
      try {
        await client.initialize();
        setInitialized(true);
      } catch (e: any) {
        BugSnag.notify(e);
      }
    };

    initialize();
  }, []);

  // Identify User on Trackers
  useEffect(() => {
    if (initialized && user) {
      client.identify(user);
    }
  }, [initialized, user, client]);

  // Hide Appsflyer Banner if specified
  useEffect(() => {
    if (disableAppsflyerBanner) hideAppsflyerBanner();
  }, [disableAppsflyerBanner]);

  // Setup Tracker with sessionData
  useEffect(() => {
    if (initialized && sessionData?.payRangeUser) {
      // Set PayRange user property
      client.setUserProperties({ isPayRange: true });

      // Hide Appsflyer Smart Banner for PayRange users
      hideAppsflyerBanner();
    }
  }, [initialized, sessionData, client]);

  return (
    <TrackingContext.Provider value={client}>
      <div id="appsflyer-banner-container" ref={appsflyerBannerRef} />
      {children}
    </TrackingContext.Provider>
  );
};
