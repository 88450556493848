import styled, { css } from 'styled-components';
import theme from 'lib/styles/theme';

const { colors } = theme;

export const Main = styled.main`
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  flex: 1 0 auto;
  position: relative;
  overflow: hidden;
  ${({ splitBg }: { splitBg?: boolean }) =>
    splitBg &&
    css`
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 200px;
        background-color: ${colors.jackpocketBlue};
        z-index: -1;
      }
    `}
`;
