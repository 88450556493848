import { useQuery } from '@tanstack/react-query';
import { hoursToMilliseconds } from 'date-fns';
import { jackpocketAPI, nextServerAPI } from 'lib/api';
import BugSnag from 'lib/bugsnag';
import useSession from 'components/hooks/use-session';

import { SUPPORTED_CARD_PROVIDER_TYPES } from 'lib/types/creditCard';
import {
  GeoSessionId,
  LocationSmartTokenResponse,
  LocationType,
  PaymentMethodKind,
  LocationResponse,
} from 'lib/types/location';
import {
  ComplianceResult,
  LocationReq,
  Coordinates,
} from 'lib/types/geolocation';
import { useGameLocation } from 'lib/atomic-state';
import { UsStateValue } from 'lib/common/states';

async function fetchLocation(
  authentication_token: string,
  stateCode: UsStateValue,
) {
  const { data } = await jackpocketAPI.get<LocationResponse>(
    `locations/${stateCode}`,
    {
      params: {
        authentication_token,
      },
    },
  );

  return data.location;
}

export const fetchLocationSmartAccessToken = async (
  locationCode: string | null,
) => {
  try {
    const { data } = await nextServerAPI.get<LocationSmartTokenResponse>(
      `geolocation/token/${locationCode}`,
    );

    return data.accessToken;
  } catch (err) {
    return null;
  }
};

export const getComplianceStatus = async (
  accessToken: any,
  geoSessionId: GeoSessionId,
  userId: string,
) => {
  try {
    const { data } = await nextServerAPI.post<ComplianceResult>(
      'geolocation/compliance',
      { accessToken, geoSessionId, userId },
    );
    return data;
  } catch (err: any) {
    BugSnag.notify(
      {
        errorClass: 'COMPLIANCE',
        errorMessage: err?.message,
      },
      (event) => {
        event.addMetadata('request-config', err?.config);
        event.addMetadata('full-error', err);
      },
    );
    throw err;
  }
};

export const getLatLngByIp = async (): Promise<Coordinates> => {
  const accessToken = await fetchLocationSmartAccessToken('TX');

  try {
    const { data } = await nextServerAPI.post<LocationReq>(
      'geolocation/location',
      { accessToken },
    );
    const [lng, lat] = data?.geoAddress?.coordinates;
    const { stateCode } = data?.ipInfo.location.stateData;

    return { lat, lng, IPstate: stateCode?.toUpperCase() } as Coordinates;
  } catch (err: any) {
    console.error('getLatLngByIp', err);
    return err;
  }
};
export const useIpLocation = () => {
  const res = useQuery({
    queryKey: ['ipLocation'],
    queryFn: async () => getLatLngByIp(),
    staleTime: hoursToMilliseconds(0.5),
  });
  return {
    ...res,
    ipLocation: res.data as Coordinates,
  };
};

const getLocationData = (location: LocationType) => {
  if (!location) {
    return null;
  }

  const { location_payment_methods, max_credit_amount, settings, time_zone } =
    location;

  // Server returns "mastercard" rather than "master" spelling in card_brands_order
  // so we need to explicitly add "master" value here
  const cardBrandsOrder = {
    ...settings.card_brands_order,
    master: settings.card_brands_order?.mastercard,
  };

  const paymentMethods = location_payment_methods.filter((p) => {
    switch (p.kind) {
      case PaymentMethodKind.credit:
      case PaymentMethodKind.debit:
        return p.provider && SUPPORTED_CARD_PROVIDER_TYPES.includes(p.provider);
      default:
        return Object.values(PaymentMethodKind).includes(p.kind);
    }
  });

  return {
    cardBrandsOrder,
    maxCreditAmount: max_credit_amount,
    paymentMethods,
    providerTypesOrder: settings.provider_types_order,
    scratchersSchedule: settings.scratchers_schedule || null,
    timeZone: time_zone,
  };
};

export const useLocationData = () => {
  const { sessionData } = useSession();
  const { gameLocation } = useGameLocation();

  const res = useQuery({
    queryKey: ['locationData', gameLocation?.value],
    queryFn: async () => {
      const location = await fetchLocation(
        sessionData.user.authentication_token,
        gameLocation?.value as UsStateValue,
      );
      return getLocationData(location);
    },
    enabled: !!gameLocation && !!sessionData.user,
    staleTime: Infinity,
  });

  return {
    ...res,
    paymentMethods: res.data?.paymentMethods || [],
    cardBrandsOrder: res.data?.cardBrandsOrder || null,
    providerTypesOrder: res.data?.providerTypesOrder || null,
    scratchersSchedule: res.data?.scratchersSchedule || null,
    timeZone: res.data?.timeZone || null,
    maxCreditAmount: res.data?.maxCreditAmount || null,
  };
};

export function useLocationSmartAccessToken(locationCode: string) {
  const res = useQuery({
    queryKey: ['accessToken', locationCode],
    queryFn: () => fetchLocationSmartAccessToken(locationCode),
    staleTime: hoursToMilliseconds(1),
  });

  return {
    ...res,
    accessToken: res.data || null,
  };
}
