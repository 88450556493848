/* eslint-disable class-methods-use-this */
/* eslint-disable lines-between-class-members */
import { initializeApp, FirebaseApp } from '@firebase/app';
import {
  initializeAnalytics,
  isSupported,
  logEvent,
  setUserId,
  setUserProperties,
  Analytics,
} from '@firebase/analytics';

import BugSnag from 'lib/bugsnag';
import { FundingHistory } from 'lib/types/fundRequest';
import { LotteryDetail } from 'lib/types/lottery';
import { Order } from 'lib/types/order';
import { Play } from 'lib/types/play';
import { User } from 'lib/types/user';
import { CheckoutType, PlayPurchaseEventOptions, Tracker } from './Tracker';

export class FirebaseTracker implements Tracker {
  private firebaseApp: FirebaseApp;
  private firebaseAnalytics: Analytics | undefined;

  private currency = 'JPC'; // Jackpocket Cents
  private defaultParams: Record<string, unknown> = {};

  constructor() {
    this.firebaseApp = initializeApp({
      apiKey: 'AIzaSyCzaz9R8N0OGMq6327iIWCCi5bEm-BlsPU',
      authDomain: 'api-project-318918674110.firebaseapp.com',
      databaseURL: 'https://api-project-318918674110.firebaseio.com',
      projectId: 'api-project-318918674110',
      storageBucket: 'api-project-318918674110.appspot.com',
      messagingSenderId: '318918674110',
      appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
      measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
    });
  }

  setDefaultParams(params: Record<string, unknown>) {
    this.defaultParams = {
      ...this.defaultParams,
      ...params,
    };
  }

  private setSrcParam() {
    const params = new URL(window.location.href).searchParams;
    const src = params.get('src');

    if (src) {
      sessionStorage.setItem('src', src);
      this.setDefaultParams({ src });
    } else {
      const sessionSrc = sessionStorage.getItem('src');
      this.setDefaultParams({ src: sessionSrc });
    }
  }

  async initialize() {
    try {
      const supported = await isSupported();

      if (supported) {
        this.firebaseAnalytics = initializeAnalytics(this.firebaseApp);

        this.setSrcParam();
      }
    } catch (e: any) {
      BugSnag.notify(e);
    }
  }

  identify(user: User) {
    try {
      if (this.firebaseAnalytics) {
        setUserId(this.firebaseAnalytics, `${user.id}`);
      }
    } catch (e: any) {
      BugSnag.notify(e);
    }
  }

  invalidateIdentity() {
    try {
      if (this.firebaseAnalytics) {
        this.trackEvent('Logout Completed');
        setUserId(this.firebaseAnalytics, '');
      }
    } catch (e: any) {
      BugSnag.notify(e);
    }
  }

  setUserProperties(properties: { [key: string]: boolean | number | string }) {
    try {
      if (this.firebaseAnalytics) {
        setUserProperties(this.firebaseAnalytics, properties);
      }
    } catch (e: any) {
      BugSnag.notify(e);
    }
  }

  private normalizeEventName(eventName: string) {
    return eventName.replace(/ |-/g, '_').replace(/'/g, '');
  }

  trackEvent(eventName: string, data: any = {}): void {
    try {
      if (this.firebaseAnalytics) {
        logEvent(this.firebaseAnalytics, this.normalizeEventName(eventName), {
          ...this.defaultParams,
          ...data,
        });
      }
    } catch (e: any) {
      BugSnag.notify(e);
    }
  }

  trackRegistrationSuccess(method = 'webapp') {
    this.trackEvent('sign_up', { method });
  }

  trackPaymentMethodAdded(paymentMethod: string, kind: string): void {
    this.trackEvent('add_payment_info', { paymentMethod, kind });
  }

  trackPlayPurchase(order: Play, options: PlayPurchaseEventOptions): void {
    const { checkoutType, playOptions } = options;

    this.trackEvent('spend_virtual_currency', {
      item_name: 'play_order',
      checkout_type: checkoutType,
      currency: this.currency,
      virtual_currency_name: this.currency,
      value: order.total,
      lottery_id: order.lottery_id,
      transaction_id: order.id,
      ...playOptions,
    });
  }

  trackMultiplayPurchase(
    order: Order,
    options: PlayPurchaseEventOptions,
  ): void {
    const { checkoutType, lotteryId, playOptions } = options;

    this.trackEvent('spend_virtual_currency', {
      item_name: 'play_order',
      checkout_type: checkoutType,
      currency: this.currency,
      virtual_currency_name: this.currency,
      value: order.total,
      lottery_id: lotteryId,
      transaction_id: order.id,
      ...playOptions,
    });
  }

  trackDigitalScratcherPurchase(order: Order, data: any): void {
    this.trackEvent('spend_virtual_currency', {
      item_name: 'digital_scratcher_order',
      currency: this.currency,
      virtual_currency_name: this.currency,
      value: order.total,
      ...data,
    });
  }

  trackFundRequest(fundRequest: FundingHistory, user: User): void {
    this.trackEvent('earn_virtual_currency', {
      item_name: 'funded_account',
      currency: this.currency,
      provider_type: fundRequest.provider_type,
      virtual_currency_name: this.currency,
      value: fundRequest.amount,
      transaction_id: fundRequest.id,
      is_first_time_deposit: user.metrics.total_fund_requests === 0,
    });
  }

  trackAddToCart(
    lottery: LotteryDetail,
    entriesCount: number,
    checkoutType: CheckoutType,
    isCustomAmount?: boolean,
  ): void {
    this.trackEvent('add_to_cart', {
      checkout_type: checkoutType,
      entries_count: entriesCount,
      is_custom_amount: Boolean(isCustomAmount),
      lottery_name: lottery.name,
      lottery_id: lottery.id,
      lottery_jackpot_value: lottery.next_jackpot,
    });
  }
}
