const theme = {
  colors: {
    primary: '#2490ef',
    accent: '#22c38e',
    bg: '#e3e8ec',
    text: '#282e33',
    black: '#111',
    white: '#fff',
    jackpocketBlue: '#1E88E5',

    gradientStart: '#83c0f6',
    gradientEnd: '#1E88E5',

    red: '#E95D4F',
    red_dark: '#DB3615',
    orange: '#FFBC42',
    green: '#42BB7D',
    green_bright: '#22C38E',
    green_light: '#DEF6EE',
    green_dark: '#1D8E54',
    purple: '#4F53B3',
    teal: '#3FABAE',

    blue_dark: '#38495c',
    blue_deep: '#166DBA',
    blue_faded: '#E8F3FC',
    blue_gray: '#D2DADF',
    blue_light: '#DDEDFB',
    blue_midnight: '#34495E',
    blue_strong: '#1C79CB',

    badge_gray_dark: '#626A74',
    badge_gray_light: '#E3E8EC',
    dark_grayish_blue: '#7E8285',
    light_grayish_blue: '#E3E8EC',
    gray_neutral: '#F3F6F9',
    gray_pale: '#e3e8ec',
    gray_smoke: '#79818B',
    gray_medium: '#546E7A',
    gray_light: '#90A4AE',
    gray_dark: '#37474F',
    gray_extra_dark: '#393939',
    gray_brown: '#979797',
    grayish_blue: '#B0BEC5',
    charcoal: '#282e33',
    border: '#d1dde8',

    yellow: '#FFC107',
  },
  mediaSizes: {
    small: 320,
    medium: 640,
    mlarge: 832,
    large: 1024,
    xlarge: 1200,
    xxlarge: 1440,
  },
  font: {
    default:
      '"Proxima Nova",-apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif',
    display:
      '"GrotaSans-ExtraBold",-apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif',
  },
};

export default theme;
