import { useQuery } from '@tanstack/react-query';
import { nextServerAPI } from 'lib/api';
import { UserSession } from 'lib/session';

export const fetchSession = async (): Promise<UserSession> => {
  const { data } = await nextServerAPI.get('session');
  return data;
};

export default function useSession() {
  const res = useQuery({
    queryKey: ['session'],
    queryFn: fetchSession,
  });
  return {
    ...res,
    sessionData: res.data as UserSession,
  };
}

export const killSession = async () => {
  try {
    await nextServerAPI.get('logout');
  } catch (e) {
    console.error('Error on Logout Attempt', e);
  }
};
