/* eslint-disable no-shadow */
export const IS_SERVER = () => {
  if (typeof window === 'undefined') return true;
  return false;
};

export const GAME_TYPES = {
  powerBall: 'powerball',
  megaMillions: 'mega_millions',
};

export const GAME_IDS = {
  powerBall: 2,
  megaMillions: 4,
};

export const SUPER_PICK_SUPPORTED_GAMES = [
  GAME_IDS.megaMillions,
  GAME_IDS.powerBall,
];

export const IS_PROD_ENV = process.env.NEXT_PUBLIC_APP_ENV === 'production';

export const IS_TEST_ENV = process.env.NEXT_PUBLIC_APP_ENV === 'test';

export const PERMITTED_GAME_IDS: number[] = Object.values(GAME_IDS);
