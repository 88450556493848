import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

if (process.env.NEXT_PUBLIC_BUGSNAG_KEY) {
  Bugsnag.start({
    apiKey: process.env.NEXT_PUBLIC_BUGSNAG_KEY,
    plugins: [new BugsnagPluginReact(React)],
    releaseStage: `${process.env.NEXT_PUBLIC_APP_ENV}`,
    appVersion: `${process.env.NEXT_PUBLIC_APP_VERSION}`,
  });
}

export default Bugsnag;
