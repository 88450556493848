import {
  LDProvider as LaunchDarklyProvider,
  LDContext,
  useFlags as useLDFlags,
} from 'launchdarkly-react-client-sdk';

import { FeatureFlags } from './server';

export const useFlags = () => useLDFlags<FeatureFlags>();

interface LDProviderProps {
  children: React.ReactNode;
  context?: LDContext;
  flags?: any;
}

export const LDProvider = ({ children, context, flags }: LDProviderProps) => {
  if (!context || !flags) {
    return <>{children}</>;
  }

  const options = {
    clientSideID: `${process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_SIDE_ID}`,
    context,
    options: {
      bootstrap: flags,
    },
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
  };

  return <LaunchDarklyProvider {...options}>{children}</LaunchDarklyProvider>;
};
