import styled, { css } from 'styled-components';
import { rem, darken } from 'polished';
import { space, SpaceProps } from 'styled-system';
import theme from 'lib/styles/theme';

type ButtonProps = SpaceProps & { h?: number; w?: number; isMuted?: boolean };

//   Default space array when using index based spacing values (in px)
//   [0, 4, 8, 16, 32, 64, 128, 256, 512]
//   Numbers greater than the length of the space array are converted to raw pixel values.
//   Styled System docs https://styled-system.com/api/#defaults

export const ButtonBase = css`
  cursor: pointer;
  height: ${(props: ButtonProps) => rem(props.h || 44)};
  width: ${(props: ButtonProps) => (props.w ? rem(props.w) : `100%`)};
  padding: 0 16px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  border-radius: 12px;
  font-size: ${rem(16)};
  text-decoration: none;
  font-weight: 700;
  transition: background-color 0.3s ease-in-out;
  background: transparent;
  -webkit-appearance: none;
  opacity: ${(props: ButtonProps) => (props.isMuted ? '0.5' : '1')};
  ${space}
`;

export const Button = styled.button`
  ${ButtonBase};
  border: 1px solid ${theme.colors.jackpocketBlue};
  background-color: ${theme.colors.jackpocketBlue};
  box-shadow: 0px 4px 8px rgba(30, 136, 229, 0.2);
  color: ${theme.colors.white};
  line-height: normal;
  text-decoration: none;
  &:hover {
    background-color: ${darken(0.1, theme.colors.jackpocketBlue)};
  }
  &:disabled {
    opacity: 0.5;
  }
`;

Button.defaultProps = {
  theme,
  h: 48,
  isMuted: false,
};

export const ButtonLight = styled(Button)`
  background-color: ${theme.colors.gray_pale};
  border: none;
  box-shadow: none;
  color: ${theme.colors.black};
  &:hover {
    background-color: ${darken(0.1, theme.colors.gray_pale)};
  }
`;

export const ButtonGray = styled(Button)`
  background-color: ${theme.colors.light_grayish_blue};
  color: ${theme.colors.text};
  border: none;
  box-shadow: none;
  &:hover {
    background-color: ${darken(0.15, theme.colors.light_grayish_blue)};
  }
`;

export const ButtonLink = styled.a`
  color: ${theme.colors.jackpocketBlue};
  font-weight: 600;
  &:hover {
    color: ${darken(0.1, theme.colors.jackpocketBlue)};
    cursor: pointer;
  }
`;

export const OptionButton = styled.button`
  height: 52px;
  width: 100%;
  padding: 0 15px;
  margin-bottom: 24px;
  background-color: ${theme.colors.white};
  border-radius: 12px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.07);
  color: ${theme.colors.charcoal};
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;

  &:hover {
    border-color: ${theme.colors.jackpocketBlue};
    cursor: pointer;
  }
`;

export const IconButton = styled(OptionButton)`
  position: relative;
  padding: 16px 15px;
  min-height: 52px;
  height: auto;
`;
