/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable class-methods-use-this */
import { FundingHistory } from 'lib/types/fundRequest';
import { LotteryDetail } from 'lib/types/lottery';
import { Play } from 'lib/types/play';
import { User } from 'lib/types/user';
import { CheckoutType, Tracker } from './Tracker';

export class MockTracker implements Tracker {
  async initialize() {}

  identify(user: User) {}

  invalidateIdentity() {}

  trackEvent(eventName: string, data: any = {}) {}

  trackRegistrationSuccess(method = 'webapp') {}

  trackPaymentMethodAdded(paymentMethod: string, kind: string): void {}

  trackPlayPurchase(order: Play): void {}

  trackFundRequest(fundRequest: FundingHistory, user: User): void {}

  trackAddToCart(
    lottery: LotteryDetail,
    entriesCount: number,
    checkoutType: CheckoutType,
    isCustomAmount?: boolean,
  ): void {}
}
