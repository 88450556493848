/* eslint-disable lines-between-class-members */
/* eslint-disable class-methods-use-this */
import * as amplitude from '@amplitude/analytics-browser';
import { UAParser } from 'ua-parser-js';
import BugSnag from 'lib/bugsnag';
import { FundingHistory } from 'lib/types/fundRequest';
import { LotteryDetail } from 'lib/types/lottery';
import { Order } from 'lib/types/order';
import { Play } from 'lib/types/play';
import { User } from 'lib/types/user';
import { CheckoutType, PlayPurchaseEventOptions, Tracker } from './Tracker';

export class AmplitudeTracker implements Tracker {
  private currency = 'JPC'; // Jackpocket Cents
  private isMobile: boolean;

  constructor() {
    const ua = UAParser();
    this.isMobile = ua.device.type === 'mobile';
  }

  async initialize() {
    await amplitude.init(process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY as string);
  }

  identify(user: User) {
    try {
      amplitude.setUserId(user.id.toString());
    } catch (e: any) {
      BugSnag.notify(e);
    }
  }

  invalidateIdentity() {
    try {
      amplitude.reset();
    } catch (e: any) {
      BugSnag.notify(e);
    }
  }

  setUserProperties(properties: { [key: string]: string | number | boolean }) {
    Object.keys(properties).forEach((key) => {
      const identifyEvent = new amplitude.Identify();
      identifyEvent.set(key, properties[key]);
      amplitude.identify(identifyEvent);
    });
  }

  trackEvent(eventName: string, data: any = {}) {
    try {
      amplitude.track(eventName, {
        is_mobile: this.isMobile,
        ...data,
      });
    } catch (e: any) {
      BugSnag.notify(e);
    }
  }

  trackRegistrationSuccess(method = 'webapp') {
    this.trackEvent('Registration Completed', { method });
  }

  trackPaymentMethodAdded(paymentMethod: string, kind: string): void {
    this.trackEvent('Payment Method Added', { paymentMethod, kind });
  }

  trackPlayPurchase(order: Play, options: PlayPurchaseEventOptions): void {
    const { checkoutType, playOptions } = options;

    this.trackEvent('Transaction Completed', {
      checkout_type: checkoutType,
      currency: this.currency,
      virtual_currency_name: this.currency,
      value: order.total,
      lottery_id: order.lottery_id,
      transaction_id: order.id,
      ...playOptions,
    });
  }

  trackMultiplayPurchase(
    order: Order,
    options: PlayPurchaseEventOptions,
  ): void {
    const { checkoutType, lotteryId, playOptions } = options;

    this.trackEvent('Transaction Completed', {
      checkout_type: checkoutType,
      currency: this.currency,
      virtual_currency_name: this.currency,
      value: order.total,
      lottery_id: lotteryId,
      transaction_id: order.id,
      ...playOptions,
    });
  }

  trackDigitalScratcherPurchase(order: Order, data: any): void {
    this.trackEvent('Digital Scratcher Transaction Completed', {
      currency: this.currency,
      virtual_currency_name: this.currency,
      value: order.total,
      ...data,
    });
  }

  trackFundRequest(fundRequest: FundingHistory, user: User): void {
    this.trackEvent('Fund Request Submitted', {
      currency: this.currency,
      provider_type: fundRequest.provider_type,
      virtual_currency_name: this.currency,
      value: fundRequest.amount,
      transaction_id: fundRequest.id,
      is_first_time_deposit: user.metrics.total_fund_requests === 0,
    });
  }

  trackAddToCart(
    lottery: LotteryDetail,
    entriesCount: number,
    checkoutType: CheckoutType,
    isCustomAmount?: boolean,
  ): void {
    this.trackEvent('Game Added to Cart', {
      checkout_type: checkoutType,
      entries_count: entriesCount,
      is_custom_amount: Boolean(isCustomAmount),
      lottery_name: lottery.name,
      lottery_id: lottery.id,
      lottery_jackpot_value: lottery.next_jackpot,
    });
  }
}
