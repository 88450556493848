import { useEffect } from 'react';
import BugSnag from 'lib/bugsnag';
import { useUser } from 'components/hooks/use-signup-query';

export default function BugSnagSetter() {
  const { user } = useUser();

  useEffect(() => {
    if (user) {
      BugSnag.setUser(`${user.uuid}`);
    }
  }, [user]);

  return null;
}
