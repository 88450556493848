import React, { useEffect } from 'react';
import Router from 'next/router';
import Head from 'next/head';
import NProgress from 'nprogress';
import { rem } from 'polished';
import theme from 'lib/styles/theme';

export const NProgressBar = () => {
  useEffect(() => {
    const handleRouteStart = () => NProgress.start();
    const handleRouteDone = () => NProgress.done();

    Router.events.on('routeChangeStart', handleRouteStart);
    Router.events.on('routeChangeComplete', handleRouteDone);
    Router.events.on('routeChangeError', handleRouteDone);

    return () => {
      Router.events.off('routeChangeStart', handleRouteStart);
      Router.events.off('routeChangeComplete', handleRouteDone);
      Router.events.off('routeChangeError', handleRouteDone);
    };
  }, []);
  return (
    <Head>
      <style
        dangerouslySetInnerHTML={{
          __html: `
       #nprogress {
        pointer-events: none;
      }
      #nprogress .bar {
        background: ${theme.colors.jackpocketBlue};
        position: fixed;
        z-index: 1031;
        top: 0;
        left: 0;
        width: 100%;
        height: ${rem(4)};
      }
      #nprogress .peg {
        display: block;
        position: absolute;
        right: 0px;
        width: 100px;
        height: 100%;
        opacity: 1;
        transform: rotate(3deg) translate(0, -${rem(4)});
      }
      `,
        }}
      />
    </Head>
  );
};

export default NProgressBar;
