import { Billable } from 'lib/types/fundRequest';
import {
  CardBrand,
  PaymentMethodKind,
  LocationPaymentMethodProvider,
} from 'lib/types/location';
import { UsStateValue } from 'lib/common/states';

export const SUPPORTED_CARD_PROVIDER_TYPES = [
  LocationPaymentMethodProvider.PS,
  LocationPaymentMethodProvider.paysafe,
];

export const DisplayBrandNameMap = {
  discover: 'Discover',
  master: 'MasterCard',
  visa: 'Visa',
};

export interface CreditCardResponse {
  bank_name: string;
  card_type: CardBrand;
  created_at_timestamp: number;
  funding_type: PaymentMethodKind.credit | PaymentMethodKind.debit;
  id: number;
  last4: string;
  providers: string[];
  purchase_abilities: string[];
  unsupported_location_codes: UsStateValue[];
  year: number;
}

export interface CreditCard extends CreditCardResponse, Billable {}

interface CreditCardProviderData {
  PS: {
    single_use_token: string;
  };
}

export interface CreditCardRequest {
  billing_country: string;
  billing_zip_code: string;
  name: string;
  provider_data: CreditCardProviderData;
}
