/* eslint-disable class-methods-use-this */
import BugSnag from 'lib/bugsnag';
import { FundingHistory } from 'lib/types/fundRequest';
import { Play } from 'lib/types/play';
import { User } from 'lib/types/user';
import { Tracker } from './Tracker';

declare global {
  interface Window {
    AF_SDK?: any;
    AF?: any;
  }
}

// This Tracker requires the application to include
// Appsflyer's Web SDK script tag in the application's <head> tag
// It is currently declared via our GTM initialized in googleTagManagerTracker.ts
export class AppsflyerTracker implements Tracker {
  private currency = 'USD';

  identify(user: User) {
    try {
      if (window.AF) {
        window.AF('pba', 'setCustomerUserId', `${user.id}`);
      }
    } catch (e: any) {
      BugSnag.notify(e);
    }
  }

  invalidateIdentity() {
    try {
      if (window.AF) {
        window.AF('pba', 'setCustomerUserId', null);
      }
    } catch (e: any) {
      BugSnag.notify(e);
    }
  }

  trackEvent(eventName: string, data: any = {}) {
    try {
      if (window.AF) {
        window.AF('pba', 'event', {
          eventName,
          eventType: 'EVENT',
          eventValue: data,
        });
      }
    } catch (e: any) {
      BugSnag.notify(e);
    }
  }

  trackRegistrationSuccess(method = 'webapp') {
    this.trackEvent('af_complete_registration', {
      af_registration_method: method,
    });
  }

  trackPaymentMethodAdded(paymentMethod: string): void {
    this.trackEvent('af_add_payment_info', {
      af_content_type: paymentMethod,
      af_success: '1',
    });
  }

  trackPlayPurchase(order: Play): void {
    this.trackEvent('af_spent_credits', {
      af_revenue: order.total / 100,
      af_currency: this.currency,
      af_quantity: order.games.length,
      af_content_id: order.lottery_id,
      af_receipt_id: order.id,
    });
  }

  trackFundRequest(fundRequest: FundingHistory, user: User): void {
    this.trackEvent('af_purchase', {
      af_revenue: fundRequest.amount / 100,
      af_currency: this.currency,
      af_content_id: 'fund_request',
      af_content_type: 'fund_request',
      af_receipt_id: fundRequest.id,
    });

    if (user.metrics.total_fund_requests === 0) {
      this.trackEvent('Unique_Funds_Request_Submitted');
    }
  }
}
